<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />

                <h2 class="brand-text text-primary ml-1">
                    {{ appName }}
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid :src="imgUrl" :alt="$t(`pages.${pagename}.pagetitle`)" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Register-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t(`pages.${pagename}.msg1`) }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t(`pages.${pagename}.msg2`) }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="registerForm">
                        <b-form class="auth-register-form mt-2">
                            <!-- username -->
                            <b-form-group
                                :label="$t('common.account')"
                                label-for="register-username"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Username"
                                    rules="required|min:3"
                                >
                                    <b-form-input
                                        id="register-username"
                                        v-model="username"
                                        name="register-username"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('common.account')"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- email -->
                            <b-form-group label="Email" label-for="register-email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email|min:3"
                                >
                                    <b-form-input
                                        id="register-email"
                                        v-model="userEmail"
                                        name="register-email"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="abc@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group
                                label-for="register-password"
                                :label="$t('common.password')"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    rules="required|min:4"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null"
                                    >
                                        <b-form-input
                                            id="register-password"
                                            v-model="password"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            :state="errors.length > 0 ? false : null"
                                            name="register-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group>
                                <b-form-checkbox
                                    id="register-privacy-policy"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    {{ $t(`pages.${pagename}.msg3`) }}
                                    <b-link>{{ $t(`pages.${pagename}.msg4`) }}</b-link>
                                </b-form-checkbox>
                            </b-form-group>

                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                @click.prevent="validationForm"
                            >
                                {{ $t('common.signup') }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <span> {{ $t(`pages.${pagename}.msg5`) }}</span>
                        <b-link :to="{ name: 'Login', params: {} }">
                            <span>{{ $t(`pages.${pagename}.msg6`) }}</span>
                        </b-link>
                    </p>

                    <!-- divider -->
                    <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

                    <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { Tientech_getPageRandomNumber } from '@/libs/tientech/api'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      pagename: 'register',
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      author,
    }
  },
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      random_number: 0,
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    try {
      // let vuethis = this
      this.initialize()
    } catch (error) {
      console.log(error)
    }
  },
  created() {
    this.random_number = Tientech_getPageRandomNumber()
  },
  methods: {
    initialize() {
      try {
        // initialize
      } catch (error) {
        console.log(error)
      }
    },
    validationForm() {
      const vuethis = this
      this.$refs.registerForm.validate().then(success => {
        if (!success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('error_common.input_error'),
              icon: 'EditIcon',
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
          return
        }
        if (!this.status) {
          this.$swal({
            icon: 'error',
            title: this.$t('PleaseAgreeToTheSiteTerms'),
            showConfirmButton: true,
            timer: 2000,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          return
        }
        let dealer = 'tientech'
        if ($themeConfig.webConfigs[window.location.host] !== undefined) {
          dealer = $themeConfig.webConfigs[window.location.host].company
        }

        const obj = {
          account: this.username,
          email: this.userEmail,
          password: this.password,
          dealer,
        }
        this.$socket.emit('accountcloud_signup',
          {
            data: obj,
            type: 'account',
            callback_params: `${this.pagename}_${this.random_number}`,
          }, params => {
            try {
              if (params.type === 'error') {
                let errormsg = vuethis.$t('error_common.unknown')
                if (params.msg === 'account_error_userisexist') {
                  errormsg = vuethis.$t('error_common.account_error_userisexist')
                }
                if (params.msg === 'account_error_emailisexist') {
                  errormsg = vuethis.$t('error_common.account_error_emailisexist')
                }
                if (params.msg === 'password is undefined') {
                  errormsg = vuethis.$t('error_common.input_error')
                }
                if (params.msg === 'email is undefined') {
                  errormsg = vuethis.$t('error_common.input_error')
                }
                if (params.msg === 'account is undefined') {
                  errormsg = vuethis.$t('error_common.input_error')
                }
                this.$swal({
                  icon: 'error',
                  title: errormsg,
                  showConfirmButton: true,
                  timer: 2000,
                  buttonsStyling: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              } else {
                this.$swal({
                  icon: 'success',
                  title: vuethis.$t('common.signup_success'),
                  text: vuethis.$t(`pages.${vuethis.pagename}.msg7`),
                  showConfirmButton: true,
                  timer: 3000,
                  buttonsStyling: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(result => {
                  this.$router.push({ name: 'Login' }).catch(error => {
                    // console.warn(error.message)
                  })
                })
              }
            } catch (error) {
              console.log(error)
            }
          })
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
